.App {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100vh;
  text-align: left;
  background-color: #edf1f7;
}
