body {
  margin: 0;
  font-family: VySans, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #edf1f7;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "VySans";
  src: local("VySans"), url(./fonts/VySans-Regular.woff) format("woff");
}

@font-face {
  font-family: "VySans-Bold";
  src: local("VySans-Bold"), url(./fonts/VySans-Bold.woff) format("woff");
}

@font-face {
  font-family: "VySans-Light";
  src: local("VySans-Light"), url(./fonts/VySans-Light.woff) format("woff");
}

@font-face {
  font-family: "VySans-RegularItalic";
  src: local("VySans-RegularItalic"),
    url(./fonts/VySans-RegularItalic.woff) format("woff");
}

@font-face {
  font-family: "VySans-LightItalic";
  src: local("VySans-LightItalic"),
    url(./fonts/VySans-LightItalic.woff) format("woff");
}

@font-face {
  font-family: "VySans-BoldItalic";
  src: local("VySans-BoldItalic"),
    url(./fonts/VySans-BoldItalic.woff) format("woff");
}

@font-face {
  font-family: "VyDisplay-Medium";
  src: local("VyDisplay-Medium"),
    url(./fonts/VyDisplay-Medium.woff) format("woff");
}
